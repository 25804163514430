import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CurrentDateTime from '../components/dateTime'
import { ArrowRight } from "react-bootstrap-icons";

const Home = () => {
  const [validated, setValidated] = useState(false)
  const [locationID, setLocationID] = useState("")

  const navigate = useNavigate()
  const locationIDRef = useRef(); // Create a reference for the input element

  // Effect to check and retrieve locationID from localStorage on component mount
  useEffect(() => {
    const storedLocationID = localStorage.getItem('locationID');
    if (storedLocationID) {
      setLocationID(storedLocationID)
    }

    // Focus on the input field when the component mounts
    locationIDRef.current.focus()
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
    
    // Redirect to the display selection page
    if (form.checkValidity()) {
      localStorage.setItem('locationID', locationID)
      navigate("/displays/" + locationID)
    }
  }

  const handleLocationIDChange = (e) => {
    setLocationID(e.target.value);
  };

  return (
    <div>
      <Row>
        <Col md={{ span: 4, offset: 5 }}>
          <div className="mt-5 mb-4">
            <h3><span>Configure This Display</span></h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 2, offset: 5 }}>
          <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="locationID">
                  <Form.Label className="col-form-label-lg">Location ID</Form.Label>
                  <Form.Control
                    size="lg"
                    ref={locationIDRef}
                    type="text" 
                    value={locationID} 
                    onChange={handleLocationIDChange}
                    placeholder="Enter your location ID"
                    required />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid location ID
                  </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" variant="primary" size="lg" className="mt-3">
                Next <ArrowRight />
              </Button>
            </Form>
          </div>      
          <div className="mt-5">
            <CurrentDateTime></CurrentDateTime>
          </div>
        </Col>
      </Row>
    </div>
  )
}
  
export default Home