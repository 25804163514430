import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { ArrowCounterclockwise } from "react-bootstrap-icons";
import { Button } from 'react-bootstrap'
import Display from '../components/display';
import { displayData } from "../displayData"  // json data source

const Displays = () => {
  const navigate = useNavigate()
  const { store_id } = useParams() // Get the 'store_id' from the URL parameter

  useEffect(() => {
    if (!store_id) { 
      navigate('/'); // Redirect to display selecting page if store_id is empty
    }
  }, [store_id, navigate]);

  // Find all displays at this location
  const findLocationId = (locationId) => {
    const result = displayData.find(locations => locations.store_id === locationId)
    return result ? result : null;
  };

  const locationObj = findLocationId(parseInt(store_id))
  const availableDisplays = locationObj.displays

  // Function to handle navigation to the home page
  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <div className="container p-3 mt-3">
        <div className="row">
          <div className="col-md-10">
            <h3>Select the role for this display at location <span className="text-primary">{ store_id }</span></h3>
          </div>
          <div className="col-md-2">
            <Button variant="outline-primary"  onClick={handleGoToHome}>
              <ArrowCounterclockwise /> Change Location
            </Button>
          </div>
        </div>
        <div className="row">
          {availableDisplays.map((display, index) => (
            <div key={index} className="col-md-4">
                <Display
                  title={display.display_name}
                  description={display.display_description}
                  store_id={ store_id }
                  display_num={display.display_num}
                  orientation={display.orientation}
                />
            </div>
          ))}
        </div>
    </div>
  );
};
  
export default Displays