import React, { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { displayData } from "../displayData"
import CurrentDateTime from '../components/dateTime'
import RotateUrls from '../components/rotateUrls';


const DisplayContent = () => {
  const { display_num } = useParams() // Get the 'display_num' from the URL parameter
  const { store_id } = useParams() // Get the 'store_id' from the URL parameter
  const [queryParameters] = useSearchParams()
  const debug = queryParameters.get("debug")

  // Get the current time info
  const currentDateTime = new Date()
  const currentDay = currentDateTime.toLocaleString("en", { weekday: "short" })  // Mon, Tue, etc
  const currentDate = currentDateTime.toLocaleString("en-CA", { year: 'numeric', month: 'numeric', day: 'numeric' })  // 2023-11-30

  useEffect(() => {
    let timeoutId;

    // Check for any new updates to the menu - currently at 2:30AM
    /* NOTE: This logic will need to change when we start pulling the data using the API */
    const scheduleReload = () => {
      const currentTime = new Date()
      // Set the desired refresh time
      const reloadTime = new Date(currentTime);
      reloadTime.setHours(2, 30, 0, 0);  // 2:30AM

      // Calculate the time until the next occurrence
      let timeUntilNextOccurrence = reloadTime - currentTime
      if (timeUntilNextOccurrence <= 0) {
        // If the desired time has already passed today, schedule for some future time
        timeUntilNextOccurrence += 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      }

      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Schedule the reload
      timeoutId = setTimeout(() => {
        window.location.reload();
      }, timeUntilNextOccurrence);
    };

    // Schedule the first reload
    scheduleReload();

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, [])

  // Find the location info for a given location id
  const findLocationId = (locationId) => {
    const result = displayData.find(locations => locations.store_id === locationId)
    return result ? result : null
  };

  const locationObj = findLocationId(parseInt(store_id))
  const availableDisplays = locationObj ? locationObj.displays : null

  if (!availableDisplays || !locationObj) {
    return <div className="m-3">Please confirm that {store_id} is a valid location ID.</div>
  }

  if(debug === "true") {
    console.log('Displays at location ' + store_id)
    console.log(availableDisplays)
    console.log('-----------------------------------')
  }
  
  // Get the playlists for the specified 'display_num'
  const displayGroups = availableDisplays.find(display => display.display_num === parseInt(display_num, 10)).playlists;

  if(debug === "true") {
    console.log('DisplayGroups matching ' + display_num)
    console.log(displayGroups)
    console.log('-----------------------------------')
  }

  // Filter playlists based on current date
  const activeDisplayGroup = displayGroups.filter(display_group => {
    const isDateInRange = DateComparison(display_group.start_date, display_group.end_date, currentDate)

    if(isDateInRange || displayGroups.length === 1) {
        return true  // found a matching date range for this display
    }

    return false   
  });

  if(debug === "true") {
    console.log('Active Display Group (date match)')
    console.log(activeDisplayGroup)
    console.log('-----------------------------------')
  }

  // Return the timeslot data that is currently active - if one exists
  const activeGroupTimeslot = activeDisplayGroup.flatMap((group, index) => {  
    const matchedTimeslot = group.timeslots.filter((timeslot) => {

      // Find matching timeslots
      const isTimeInRange = TimeRangeComparison(timeslot.start_time, timeslot.end_time)
      const isDayInRange  = DayWeekComparison(timeslot.week_days, currentDay)

      if(isDayInRange && isTimeInRange) {
        return true // found matching timeslot
      }
      return false
    })

    return matchedTimeslot
  })
  
  if(debug === "true") {
    console.log('Active Display Group Timeslots (time match)')
    console.log(activeGroupTimeslot)
    console.log('-----------------------------------')
  }

  // Compare time/day to find timeslot matches
  function TimeRangeComparison(startTimeString, endTimeString) {

    const [isWithinTimeRange, setIsWithinTimeRange] = useState(false)

    useEffect(() => {
      const checkTimeRange = () => {
        
        // Use timestamps only
        const timeOptions = { hourCycle: "h23"};
        var currentTime = new Date().toLocaleTimeString('en-US', timeOptions)
        var startTime = startTimeString
        var endTime = endTimeString

        if(debug === true) {
          console.log('start: ' + startTime + ', current: ' + currentTime + ', end: ' + endTime)
        }
        setIsWithinTimeRange(currentTime >= startTime && currentTime <= endTime)
      };
  
      // Compare the date range on mount
      checkTimeRange();
  
      // Set up an interval to compare every 30 seconds
      const intervalId = setInterval(checkTimeRange, 30000)
  
      // Clear the interval on unmount to avoid memory leaks
      return () => clearInterval(intervalId);
    }, [startTimeString, endTimeString])
  
    if(isWithinTimeRange) {
      return true
    }  
    return false
  };
  
  // Check if the current day of the week is enabled for this timeslot
  function DayWeekComparison(timeslotDays, currentDay) {
    if(timeslotDays.includes(currentDay)) {
      return true
    }
    return false
  }

  // Check if the currentDate is between start and end dates
  function DateComparison(startDate, endDate, currentDate) {
    const isBetweenDates = startDate <= currentDate && currentDate <= endDate
    return isBetweenDates
  }

  // prepare the html output
  function renderOutput() {
    if(debug === "true") {
      return (
        <div className="m-3">
          <h4>Location {store_id},  Display Number {display_num}</h4>
          {activeDisplayGroup.map((group, index) => (
            <div key={index} className="mb-3">
              Effective: {group.start_date} to {group.end_date}
            </div>
          ))}
          <div>Active Timeslot:</div>
          <ul>
            {activeGroupTimeslot.map((timeslot, index) => (
              <li key={index}>
                <strong>Timeslot Name:</strong> {timeslot.timeslot_name}<br />
                <strong>Days of Week:</strong> {timeslot.week_days}<br />
                <strong>Start Time:</strong> {timeslot.start_time}<br />
                <strong>End Time:</strong> {timeslot.end_time}<br />
                <strong>Transition:</strong> {timeslot.transition}<br />
                <strong>Content URLs:</strong><br />
                <ul>
                  {timeslot.content.map((content, contentIndex) => (
                    <li key={contentIndex}>
                      URL: {content.url},  Rotate: {content.rotate !== null ? content.rotate + " secs": "Not set"}<br />
                      <img src={content.url} alt="Display" height="100px" /><br/>
                    </li>
                  ))}
                </ul>
              </li>            
            ))}
          </ul>
        
          <div className="m-2">
            <div>Active Display:</div>
            {activeGroupTimeslot.map((timeslot, index) => (
              <RotateUrls key={index} urls={timeslot.content} debug={debug} />
            ))}
            <CurrentDateTime></CurrentDateTime>
          </div>
        </div>
      )
    } else {
      if(activeGroupTimeslot.length !== 0) {
        return (
          <div>
            {activeGroupTimeslot.map((timeslot, index) => (
              <RotateUrls key={index} urls={timeslot.content} transition={timeslot.transition}/>
            ))}
          </div>
        )
      } else {
        return <div className="m-4 text-muted">No Timeslot Content</div>
      }     
    }
  }

  return (
   renderOutput()
  )
}

export default DisplayContent;