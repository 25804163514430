import React, { useState, useEffect, useRef } from 'react';
import '../displays.css';
import './fadeInOut.css';

/**
 * Change between urls with the fade in/out transtion type
 * 
 * @param {urls} object URLs & timing for rotation
 */
const RotateUrls = ({ urls }) => {
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0)
  const [burnInAdj, setBurnInAdj] = useState(true)
  const [iframeVisible, setIframeVisible] = useState(true)
  const [isFading, setIsFading] = useState(false)
  const rotateSecs = urls[currentUrlIndex].rotate;
  const burnInRotateTime = 60  // minutes
  const iframeRef = useRef(null)

useEffect(() => {    
    if (!isFading) { // Update the iframe src after the fade-out is complete
      setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }
  }, [isFading, urls.length]);

  useEffect(() => {   
    if (isFading) {   // Hide the iframe with a fade-out transition
      const iframe = iframeRef.current;

      // Add an event listener to detect when the fade-out transition has finished
      const handleTransitionEnd = () => {
        setIsFading(false); // Reset the isFading state after the fade-out is complete
      };

      // Attach the event listener to the iframe
      iframe.addEventListener('transitionend', handleTransitionEnd);

      setIframeVisible(false);
    }
  }, [isFading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
    }, rotateSecs * 1000); // Convert seconds to milliseconds

    const burnInPadding = setInterval(() => {
      setBurnInAdj((prevBurnInAdj) => !prevBurnInAdj);
    }, burnInRotateTime * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearInterval(burnInPadding);
    }; // Clean up the intervals on component unmount
  }, [rotateSecs]);

  useEffect(() => {
    // After a short delay, show the fade-in the iframe
    if (!isFading) {
      const timeout = setTimeout(() => {
        setIframeVisible(true);
      }, 100); // delay between images

      return () => clearTimeout(timeout);
    }
  }, [isFading]);


  function renderOutput() {
    return (
        <div id="iframeContainer">
          <iframe
            key={currentUrlIndex}  // prevent back button from cycling through iframe pages
            src={urls[currentUrlIndex].url}
            className={`fade-transition ${burnInAdj ? 'antiBurnInPadding' : 'noBurnInPadding'}`}
            title="description"
            style={{ opacity: iframeVisible ? 1 : 0 }} // Use inline styles to set opacity
            ref={iframeRef} // Ref for getting iframe contentWindow, if needed
          />
        </div>
    )
  }

  return renderOutput();
};

export default RotateUrls;
