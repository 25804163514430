import './displays.css';

export default function Display({title, description, store_id, display_num, orientation="landscape"}) {
    return (
        <a className="display-card-link" href={"/content/" + store_id + "/" + display_num }>
            <div className={`card display-card my-5 ${orientation === "portrait" ? "v-display-card" :  "h-display-card"}`}>
            <h3>{title}</h3>
                <div className="lh-1 text-white-50">{description}</div>
            </div>
        </a>
    );
}