import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './App.scss';

import Home from './pages';
import Displays from './pages/selectDisplay';
import DisplayContent from './pages/displayContent';
  
function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/displays/:store_id?" element={<Displays />} />
          <Route path="/content/:store_id?/:display_num?" element={<DisplayContent />} />
          <Route path="/:store_id/:display_num" element={<DisplayContent />} />
      </Routes>
    </Router>
  );
}
  
export default App;
