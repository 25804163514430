import React, { useState, useEffect } from 'react';
import SlideTransition from '../components/transitions/slide'
import InstantChange from '../components/transitions/instantChange'
import FadeInOut from '../components/transitions/fadeInOut'

// Determine the proper component for the requested transition on this timeslot 
const RotateUrls = ({ urls, transition, debug }) => {
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0)
    const rotateSecs = urls[currentUrlIndex].rotate;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % urls.length);
        }, rotateSecs * 1000); // Convert seconds to milliseconds
    
        return () => {
            clearInterval(interval);
        };
      }, [urls.length, rotateSecs]);

    if (debug === "true") {
        return (
            <div>
                <img src={urls[currentUrlIndex].url} alt="" height="100px" />
                <div>{urls[currentUrlIndex].url}</div>
            </div>
        );
    } else {
        const slideOptions = ["slideLeft", "slideRight", "slideUp", "slideDown"]

        if(slideOptions.includes(transition)) {
            return (
                <SlideTransition urls={urls} transition={transition} debug={debug}></SlideTransition>
            )
        } else if (transition === 'fade') {           
            return (
                <FadeInOut urls={urls} debug={debug}></FadeInOut>
            )
        } else {
            return (
                <InstantChange urls={urls} debug={debug}></InstantChange>
            )
        }
    } 
}

export default RotateUrls