import  React, { useState , useEffect } from 'react'

export const CurrentDateTime = () => {

    var [date,setDate] = useState(new Date());
    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    var timeOptions = { hourCycle: "h23" };
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }   
    });

    return(
        <div className="mt-3">
            <div>{date.toLocaleDateString('us-en', dateOptions)} {date.toLocaleTimeString('us-en', timeOptions)}</div>
        </div>
    )
}

export default CurrentDateTime