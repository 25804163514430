import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../displays.css';
import './slide.css';

/**
 * Change between urls with the slide left transition
 * 
 * @param {urls} object URLs & timing for rotation
 */
const SlideTransition = ({ urls, transition }) => {
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const [burnInAdj, setBurnInAdj] = useState(true);
  const rotateSecs = urls[currentUrlIndex].rotate;
  const burnInRotateTime = 60; // minutes
  const iframeRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }, rotateSecs * 1000); // Convert seconds to milliseconds

    const burnInPadding = setInterval(() => {
      setBurnInAdj((prevBurnInAdj) => !prevBurnInAdj);
    }, burnInRotateTime * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearInterval(burnInPadding);
    }; // Clean up the intervals on component unmount
  }, [urls.length, rotateSecs]);

  // Set the css transition style name
  function setSlideStyle() {
    let slideStyle;

    if(transition === 'slideUp') {
        slideStyle = 'slide-up-transition'
    } else if (transition === 'slideDown') {
        slideStyle = 'slide-down-transition'
    } else if (transition === 'slideRight') {
        slideStyle = 'slide-right-transition'
    } else {
        slideStyle = 'slide-left-transition'
    }
    
    return slideStyle
  }

  function renderIframe() {
    const slideStyle = setSlideStyle()
    return (
      <iframe
        src={urls[currentUrlIndex].url}
        className={`${slideStyle} ${burnInAdj ? 'antiBurnInPadding' : 'noBurnInPadding'}`}
        title="description"
        ref={iframeRef}
      />
    );
  }

  function renderOutput() {
    const slideStyle = setSlideStyle()
    return (
        <div id="iframeContainer">
          <TransitionGroup>
            <CSSTransition
              key={currentUrlIndex} // Ensure the transition is triggered when the key changes
              timeout={500}
              classNames={slideStyle}
              unmountOnExit
            >
              {renderIframe()}
            </CSSTransition>
          </TransitionGroup>
        </div>
    )
  }
  return renderOutput();
};

export default SlideTransition;