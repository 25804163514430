import React, { useState, useEffect, useRef } from 'react';
import '../displays.css';

/**
 * Change between urls with no specific transition type
 * 
 * @param {urls} object URLs & timing for rotation
 */
const InstantChange = ({ urls }) => {
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const [burnInAdj, setBurnInAdj] = useState(true);
  const rotateSecs = urls[currentUrlIndex].rotate;
  const burnInRotateTime = 10; // minutes

  const nextUrlIndexRef = useRef((currentUrlIndex + 1) % urls.length);
  const preloadedIframeRef = useRef(null);

  useEffect(() => {
    // Preload the next URL in a hidden iframe
    const preloadNextUrl = () => {
      const nextUrlIndex = (currentUrlIndex + 1) % urls.length;
      const preloadedIframe = document.createElement('iframe');
      preloadedIframe.src = urls[nextUrlIndex].url;
      preloadedIframe.style.display = 'none';
      preloadedIframeRef.current = preloadedIframe;
      document.body.appendChild(preloadedIframe);
      nextUrlIndexRef.current = nextUrlIndex;
    };

    preloadNextUrl(); // Preload the next URL when the currentUrlIndex changes

    // Clear the preloaded iframe on component unmount
    return () => {
      if (preloadedIframeRef.current) {
        preloadedIframeRef.current.remove();
        preloadedIframeRef.current = null;
      }
    };
  }, [currentUrlIndex, urls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }, rotateSecs * 1000); // Convert seconds to milliseconds

    const burnInPadding = setInterval(() => {
      setBurnInAdj((prevBurnInAdj) => !prevBurnInAdj);
    }, burnInRotateTime * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearInterval(burnInPadding);
    }; // Clean up the intervals on component unmount

  }, [urls.length, rotateSecs, burnInRotateTime]);

  function renderOutput() {
    return (
      <div>
        <iframe
          key={currentUrlIndex} // prevent back button from cycling through iframe pages
          src={urls[currentUrlIndex].url}
          className={burnInAdj ? 'antiBurnInPadding' : 'noBurnInPadding'}
          title="description"
        />
      </div>
    );
  }

  return renderOutput();
};

export default InstantChange;